import React from "react"
// import Img from "gatsby-image"
// import { useStaticQuery, graphql } from "gatsby"
import style from "./footer.module.css"
import wordmark_white from '../../images/brand/wordmark_white.svg';

// import classNames from "classnames/bind"
// const cx = classNames.bind(style)

export default function Footer() {
    return (
        <>
            <div className={style.footer}>
                <div className={style.contact}>

                </div>
                <div className={style.logoPlusTagline}>
                    <img className={style.wordmark} src={wordmark_white} alt="wordmark.svg" />
                    <div id="tagline" className={style.tagline}>
                        spaces for growth
                </div>
                </div>
            </div>

        </>
    )
}


